import React from "react";
import get from "lodash/get";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../layouts";

class Terms extends React.Component {
  render() {
    const imgLogoSrc = get(
      this,
      "props.data.contentfulCompanyLogo.logo.file.url"
    );

    const bodyTheme = get(
      this,
      "props.data.contentfulSiteConfiguration.bodyTheme"
    );
    const termsOfServiceText = get(this, "props.data.contentfulToS.tos.json");

    return (
      <Layout imgSrc={imgLogoSrc}>
        <div className={`${bodyTheme}Text`}>
          {documentToReactComponents(termsOfServiceText)}
        </div>
      </Layout>
    );
  }
}

export default Terms;

export const pageQuery = graphql`
  query GetTermPage {
    contentfulSiteConfiguration {
      bodyTheme
    }
    contentfulCompanyLogo {
      logo {
        file {
          url
        }
      }
    }
    contentfulToS {
      tos {
        json
      }
    }
  }
`;
